module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-155531203-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Construir Sozinho","short_name":"CS","start_url":"/","background_color":"#1a202c","theme_color":"#f6e05e","display":"standalone","icon":"src/images/construir-sozinho-logo-4-circle.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e8952dd1803f33fceaa04bf2f1ddbd75"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
